.logo {
	height: 64px;
	width: 64px;
}
.logo:hover {
	-webkit-animation: spin 4s linear infinite;
	-moz-animation: spin 4s linear infinite;
	animation: spin 4s linear infinite;
}
	.spin {
		width: 5em;
		-webkit-animation: spin 4s linear infinite;
		-moz-animation: spin 4s linear infinite;
		animation: spin 4s linear infinite;
	}
@-moz-keyframes spin {
	100% {
		-moz-transform: rotate(360deg);
	}
}
@-webkit-keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
#path36 {
	fill: #f5a012;
}
#path34 {
	fill: #038bd1;
}

#path30 {
	fill: #68ab23;
}

#path26 {
	fill: #363d4d;
}
